import { createRouter, createWebHistory } from 'vue-router';
import Home from "./views/HomePage.vue";

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "",
      name: "home",
      component: Home,
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/AboutPage.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () =>
        import(/* webpackChunkName: "contact" */ "./views/ContactPage.vue"),
    },
    {
      path: "/project/:projectId/:routeName",
      name: "project",
      component: () =>
        import(/* webpackChunkName: "project" */ "./views/ProjectPage.vue"),
    },
    {
      path: "/impressum",
      name: "impressum",
      component: () =>
        import(/* webpackChunkName: "impressum" */ "./views/ImpressumPage.vue"),
    },
    {
      path: "/datenschutz",
      name: "datenschutz",
      component: () =>
        import(/* webpackChunkName: "datenschutz" */ "./views/DatenschutzPage.vue"),
    },
  ],
});

export default router;
