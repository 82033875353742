<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
    </div>
    <div id="content">
      <router-view v-slot="{ Component }">
        <transition mode="in-out">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <div v-if="$route.name == 'about'" id="footer">
      <a href="/impressum">Impressum</a>
      <br />
      <a href="/datenschutz">Datenschutz</a>
    </div>
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: "Plex Mono Bold";
  src: local("PlexMonoBold"),   url(./fonts/IBMPlexMono-Bold.woff2) format("woff2");
  font-weight: bold
}
@font-face {
  font-family: "Plex Sans Regular";
  src: local("PlexSansRegular"),   url(./fonts/IBMPlexSans-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Plex Sans Text";
  src: local("PlexSansText"),   url(./fonts/IBMPlexSans-Text.woff2) format("woff2");
}
@font-face {
  font-family: "Plex Mono Light";
  src: local("PleMonoLightx"),   url(./fonts/IBMPlexMono-Light.woff2) format("woff2");
  font-weight: medium
}
@font-face {
  font-family: "Plex Mono Light";
  src: local("PleMonoLight"),   url(./fonts/IBMPlexMono-Light.woff2) format("woff2");
}
body {
  margin: 0;
  padding: 0;

  #app {
    font-family: "Plex Mono Light";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: right;
    color: black;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p strong {
      font-family: "Plex Mono Bold"
    }
    h1 {
      font-size: 2em;
    }

    h1,h2,h3 {
      font-family: "Plex Mono Bold";

    }

    #nav {
      width: 80%;
      margin: 30px;
      margin-bottom: 5px;
      display: flex;
      justify-content: flex-end;
      text-align: right;

      a {
        font-family: "Plex Mono Bold";
        padding: 10px;
        font-weight: bold;
        color:black;
        text-decoration: none;
        font-size: 1.5em;

        &.router-link-exact-active {
          text-decoration-line: underline;
        }
        &.router-link-exact-hover {
          color: #628270;
        }
        &:hover {
          color: #628270;
        }
      }

      @media only screen and (max-width: 736px) {
        margin-right: 0;
        a {
          padding-right: 0;
        }
      }
    }

    .tile a {
      font-weight: bold;
      color: black;
      text-decoration: none;
    }

    a {
      color: black;
      text-decoration: none;
      font-weight: bold;
      &:visited {
        color: black;
      }
      &:hover {
        color: #628270;
      }
    }

    #content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      width: 90vw;
    }

    #footer {
      display: flex;
      align-items: flex-end;
      a {
        padding: 5px;
      }
    }

    /* animations */

    .router-anim-enter-active {
      animation: coming 0.5s;
      animation-delay: 0.3s;
      opacity: 0;
    }
    .router-anim-leave-active {
      animation: going 0.4s;
    }

    @keyframes going {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(50px);
        opacity: 0;
      }
    }
    @keyframes coming {
      from {
        transform: translateY(50px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @media only screen and (max-width: 736px) {
      #content {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .tileCont {
        width: 100%;
      }

      .content .flex-half {
        width: 100%;
      }

      .content #aboutText {
        width: 100%;
      }
    }
  }
}
</style>
